@keyframes animate-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.will-animate-up {
  transform: translateY(50px);
  opacity: 0;
  transition: all 0.5s ease;
  transition-delay: 0.25s;
}

.animate-up {
  transform: translateY(0);
  opacity: 1;
}

.do-animate-up {
  animation: animate-up 0.5s ease forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  transform: translateX(100%);
  opacity: 0;
  animation: slide-in 0.5s ease forwards;
}

.delay-1 {
  animation-delay: 0.1s;
  transition-delay: 0.1s;
}

.delay-2 {
  animation-delay: 0.2s;
  transition-delay: 0.2s;
}

.delay-3 {
  animation-delay: 0.3s;
  transition-delay: 0.3s;
}

.delay-4 {
  animation-delay: 0.4s;
  transition-delay: 0.4s;
}

.delay-5 {
  animation-delay: 0.5s;
  transition-delay: 0.5s;
}

.delay-6 {
  animation-delay: 0.6s;
  transition-delay: 0.6s;
}

.delay-7 {
  animation-delay: 0.7s;
  transition-delay: 0.7s;
}

.delay-8 {
  animation-delay: 0.8s;
  transition-delay: 0.8s;
}

.delay-9 {
  animation-delay: 0.9s;
  transition-delay: 0.9s;
}

.delay-10 {
  animation-delay: 1s;
  transition-delay: 1s;
}

.hero-line-1 {
  opacity: 0;
  transform: translateY(10px);
  animation: animate-up 0.5s ease forwards;
  animation-delay: 0.25s;
  transition-delay: 0.25s;
}

.hero-line-2 {
  opacity: 0;
  transform: translateY(10px);
  animation: animate-up 0.5s ease forwards;
  animation-delay: 1s;
  transition-delay: 1s;
}
